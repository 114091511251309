import cx from 'classnames';
import { forwardRef } from 'react';

import { twMerge } from '~/utils/twMerge';

import { Loader } from './loaders/Loader';

export type ButtonVariant =
  | 'legacy-muted'
  | 'legacy-neutral'
  | 'legacy-link'
  | 'legacy-link-text'
  | 'brand-fill'
  | 'brand-outline'
  | 'brand-secondary'
  | 'ghost'
  | 'registration'
  | 'registration-yellow'
  | 'registration-outline'
  | 'danger-fill'
  | 'default-fill'
  | 'compact-dropdown-brand-ghost'
  | 'secondary-outline';

export type ButtonSize = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
type ButtonShape = 'none' | 'square' | 'circle';

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  type: React.ComponentPropsWithoutRef<'button'>['type']; // Make type explicit to avoid subtle errors
  loading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  shape?: ButtonShape;
  outline?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function ButtonWithoutRef(
  {
    size = 'md',
    variant = 'brand-fill',
    shape = 'none',
    outline,
    className,
    loading,
    disabled,
    children,
    startIcon,
    endIcon,
    ...rest
  },
  ref,
) {
  const isLinkText = variant === 'legacy-link-text';
  const isLegacyVariant = variant.startsWith('legacy-');

  return (
    <button
      ref={ref}
      className={cx(
        getButtonClassNames({
          size,
          variant,
          shape,
          isLegacy: isLegacyVariant,
          isLinkText,
        }),
        {
          'btn-outline': isLegacyVariant && outline,
        },
        className,
      )}
      disabled={loading || disabled}
      {...rest}
    >
      {loading ? <Loader variant="inherit" size="sm" /> : null}
      {startIcon ? startIcon : null}
      {children}
      {endIcon ? endIcon : null}
    </button>
  );
});

const classesByVariant: Record<ButtonVariant, string | string[]> = {
  // Button
  'brand-fill': [
    'bg-brand-blue border border-transparent outline-2 outline-brand-blue/10 outline-offset-1 text-s-medium text-white whitespace-nowrap',
    'hocus:enabled:bg-brand-dark-blue focus:enabled:outline',
    'aria-expanded:bg-brand-dark-blue aria-expanded:outline',
    'disabled:bg-warmgray-500 disabled:cursor-not-allowed',
  ],
  'default-fill': [
    'bg-black border border-transparent outline-2 outline-brand-blue/10 outline-offset-1 text-s-medium text-white whitespace-nowrap',
    'hocus:enabled:bg-brand-dark-blue focus:enabled:outline',
    'aria-expanded:bg-brand-dark-blue aria-expanded:outline',
    'disabled:bg-warmgray-500 disabled:cursor-not-allowed',
  ],
  'brand-outline': [
    'border border-strong rounded-md text-s-medium outline-2 outline-brand-blue/10 outline-offset-1 whitespace-nowrap',
    'focus:enabled:outline hocus:enabled:border-brand-blue hover:border-brand-blue hover:before:hidden',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-brand-blue',
    'disabled:text-inactive disabled:border-warmgray-100 hocus:border-warmgray-100 disabled:cursor-not-allowed',
  ],
  registration: [
    'bg-brand-green border border-transparent outline-2 outline-brand-green/10 outline-offset-1 text-s-semibold text-white whitespace-nowrap',
    'hocus:enabled:bg-brand-green focus:enabled:outline',
    'aria-expanded:bg-brand-green aria-expanded:outline',
    'disabled:bg-warmgray-500 disabled:cursor-not-allowed',
  ],
  'registration-yellow': [
    'bg-brand-yellow border border-transparent outline-2 outline-brand-yellow/10 outline-offset-1 text-s-semibold text-black whitespace-nowrap',
    'hocus:enabled:bg-brand-yellow focus:enabled:outline',
    'aria-expanded:bg-brand-yellow aria-expanded:outline',
    'disabled:bg-warmgray-500 disabled:cursor-not-allowed',
  ],
  'registration-outline': [
    'border border-strong rounded-md text-s-medium outline-2 outline-light-gray/10 outline-offset-1 whitespace-nowrap shadow-drop-shadow',
    'focus:enabled:outline hocus:enabled:border-brown-500 hover:border-brown-500 hover:before:hidden',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-brown-500',
    'disabled:text-coldgray-500 disabled:cursor-not-allowed disabled:hocus:border-transparent',
  ],
  ghost: ['text-s-medium border border-transparent hocus:outline-none hocus:text-brand-blue'],
  'brand-secondary': [
    'border border-warmgray-100 text-brand-blue text-s-medium outline-2 outline-brand-blue/10 outline-offset-1 whitespace-nowrap',
    'focus:enabled:outline hocus:enabled:border-brand-blue',
    'aria-expanded:before:hidden aria-expanded:outline aria-expanded:border-brand-blue',
    'disabled:text-coldgray-500 disabled:cursor-not-allowed',
  ],
  'danger-fill': [
    'bg-red-500 border border-transparent outline-2 outline-red-500/10 outline-offset-1 text-s-medium text-white whitespace-nowrap',
    'hocus:enabled:bg-red-600 focus:enabled:outline',
    'aria-expanded:bg-red-600 aria-expanded:outline',
    'disabled:bg-warmgray-500 disabled:cursor-not-allowed',
  ],
  'secondary-outline': [
    'border border-warmgray-100 text-s-semibold whitespace-nowrap',
    'focus:enabled:outline hocus:enabled:bg-warmgray-50',
    'aria-expanded:before:hidden aria-expanded:outline',
    'disabled:text-inactive disabled:cursor-not-allowed',
  ],

  // Dropdown
  'compact-dropdown-brand-ghost': [
    'text-s-semibold border border-transparent text-brand-blue hocus:outline-none hocus:bg-blue-50 hocus:text-blue-400',
  ],

  // Legacy
  'legacy-neutral': 'btn-neutral',
  'legacy-muted': '',
  'legacy-link': 'btn-link no-underline hocus:underline font-normal normal-case',
  'legacy-link-text': 'link link-hover link-brand-blue',
};

const legacyClassesBySize: Record<ButtonSize, string> = {
  xxs: 'btn-xxs',
  xs: 'btn-xs',
  sm: 'btn-sm',
  md: 'btn-md',
  lg: 'btn-lg',
  xl: 'btn-xl',
};

const classesBySize: Record<ButtonSize, string> = {
  xxs: 'px-1.5 py-[.1875rem] rounded-md leading-[1.125rem]', // 20px
  xs: 'px-1.5 py-px rounded-md', // 24px
  sm: 'px-2 py-1 rounded-md', //30
  md: 'px-2 py-[0.3125rem] rounded-lg', // 32px
  lg: 'px-2 py-[0.4375rem] rounded-lg', // 36px
  xl: 'px-2.5 py-[0.6875rem] rounded-lg', // 44px
};

const legacyClassesByShape: Record<ButtonShape, string> = {
  none: '',
  square: 'btn-square',
  circle: 'btn-circle',
};

const classesByShape: Record<ButtonShape, string> = {
  none: '',
  square: '',
  circle: 'rounded-full',
};

const squareLikeClassesOverridesBySize: Record<ButtonSize, string> = {
  xxs: 'p-[.1875rem]',
  xs: 'p-px',
  sm: 'p-1',
  md: 'p-[0.3125rem]',
  lg: 'p-[0.4375rem]',
  xl: 'p-[0.6875rem]',
};

export const classesByShapeAndSize: Partial<Record<ButtonShape, Record<ButtonSize, string>>> = {
  square: squareLikeClassesOverridesBySize,
  circle: squareLikeClassesOverridesBySize,
};

const classesByVariantAndSize: Partial<Record<ButtonVariant, Record<ButtonSize, string>>> = {
  'brand-fill': {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
  'brand-secondary': {
    xxs: '',
    xs: '',
    sm: '',
    md: 'text-s-semibold',
    lg: 'text-s-semibold',
    xl: 'text-s-semibold',
  },
};

export function getButtonClassNames({
  variant,
  size,
  shape,
  isLegacy,
  isLinkText,
}: {
  variant: ButtonVariant;
  size: ButtonSize;
  shape: ButtonShape;
  isLegacy: boolean;
  isLinkText?: boolean;
}) {
  if (isLegacy) {
    if (isLinkText) {
      return classesByVariant[variant];
    }

    return twMerge(
      'btn flex-nowrap',
      classesByVariant[variant],
      legacyClassesBySize[size],
      legacyClassesByShape[shape],
    );
  }

  return twMerge(
    'inline-flex items-center gap-1 text-center justify-center',
    classesByVariant[variant],
    classesBySize[size],
    classesByVariantAndSize[variant]?.[size],
    classesByShape[shape],
    classesByShapeAndSize[shape]?.[size],
  );
}
